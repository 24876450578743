import * as React from "react";

import { cn } from "@/lib/utils";
import { getSupportedCallingCodes, parsePhoneNumber } from "awesome-phonenumber";
// import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./select";
// import { FormControl } from "./form";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, ...props }, ref) => {
  return (
    <input
      type={type}
      className={cn(
        `flex h-10 w-full outline-none rounded-md border border-black bg-white px-3 py-2 text-sm ring-offset-white 
        file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 
        focus-visible:outline-none focus-visible:ring-2 focus-visible:dijon  
        focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 
     `,
        className
      )}
      ref={ref}
      {...props}
    />
  );
});
Input.displayName = "Input";

const PhoneNumberInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, value, onChange, ...props }, ref) => {
    const phoneNumber = value?.toString() || "";
    const codesList = getSupportedCallingCodes()
      .sort()
      .map((code) => `+${code}`);
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
    let countryCode = "";
    let significantNumber = "";

    if (parsedPhoneNumber.valid) {
      countryCode = (parsedPhoneNumber.countryCode && `+${parsedPhoneNumber.countryCode}`) || "";
      significantNumber = parsedPhoneNumber?.number?.significant || "";
    } else {
      const matchedCode = codesList.find((code) => phoneNumber.startsWith(code));
      if (matchedCode) {
        countryCode = matchedCode;
        significantNumber = phoneNumber.slice(matchedCode.length);
      } else {
        countryCode = "";
        significantNumber = phoneNumber;
      }
    }

    // const onCountryCodeChange = (value: string) => {
    //   const resultEvt = { target: { value: `${value}${significantNumber}` } } as React.ChangeEvent<HTMLInputElement>;
    //   onChange && onChange(resultEvt);
    // };

    const onSignificantNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const resultEvt = {
        target: { value: `${countryCode}${event.target.value}` },
      } as React.ChangeEvent<HTMLInputElement>;
      onChange && onChange(resultEvt);
    };

    return (
      <div className="flex flex-row gap-4">
        {/* <Select defaultValue={codesList[0]} value={countryCode} onValueChange={onCountryCodeChange}>
          <FormControl>
            <SelectTrigger className="w-[90px]">
              <SelectValue />
            </SelectTrigger>
          </FormControl>
          <SelectContent>
            {codesList.map((code, index) => (
              <SelectItem key={index} value={code}>
                {code}
              </SelectItem>
            ))}
          </SelectContent>
        </Select> */}
        <input
          className={cn(
            "flex h-10 w-full outline-none rounded-md border border-black bg-white px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-dijon focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
            className
          )}
          onChange={onSignificantNumberChange}
          value={significantNumber}
          ref={ref}
          {...props}
          // type="number"
        />
      </div>
    );
  }
);
PhoneNumberInput.displayName = "PhoneNumberInput";

export { Input, PhoneNumberInput };
